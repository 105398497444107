import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../Layout'
import SEO from '../components/SEO'

import AsideImage from '../components/AsideImage/AsideImage'
import Button from '../components/Button/Button'

const StyledCenter = styled.div`
  margin: 24px 0;
  text-align: center;
`

const WizytaPage = ({ data }) => {
  return (
    <Layout
      header="Umów się"
      headerBold="na wizytę"
      asideContent={[
        <AsideImage
          key="asideImage"
          srcImage={data.coverPhoto.childImageSharp.fluid}
        >
          <Button to="/kontakt">Sprawdź dojazd</Button>
        </AsideImage>,
      ]}
    >
      <SEO title="Wizyta | Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn" />
      <p>
        Wizytę w naszym gabinecie można umówić telefonicznie (+ 48 501 530 196).
        Honorujemy płatności kartą.
      </p>
      <p>
        Można skorzystać również z formularza w aplikacji IQ Dental, korzystając
        z poniższego linka:
      </p>
      <StyledCenter>
        <Button to="https://iqdental.app/rejestracja-wizyty/6b3cd188-16dc-42ad-91ae-4a073eb8795a/new">
          Umów wizytę przez IQ Dental
        </Button>
      </StyledCenter>
    </Layout>
  )
}

export default WizytaPage

export const pageQuery = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
